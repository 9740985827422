import React from 'react';
const Input = (props) => {
   
    return (
    <div className="form-group">
    <label htmlFor={props.name} className="from-control">{props.label}
    <input  className="form-control"
    onChange={props.onChange}
    value={props.value}  id={props.name}
     name={props.name} type={props.type}
    >
    </input>
    { props.error && <div className="alert alert-danger">{props.error}</div> }
    </label>
   </div>  );
}
 
export default Input;