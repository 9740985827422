import React from 'react';
const BookType = (props) => {
    
    const {bookTypes:btypes,onBookTypeSelect,selectedBookType}= props;
    console.log("selectedBookType",selectedBookType);
    
    return ( 
        <div>
            <ul className="list-group">
            {btypes.map( bt =>(
                
                <li className={ selectedBookType === bt._id?"list-group-item active1" : "list-group-item" } 
                key={bt._id} 
                onClick={()=> onBookTypeSelect(bt)}
                >
                
                {bt.name}
                
                </li>
            


            ) )}
        </ul>
        </div>
        );
}
 
export default BookType;

