import _ from 'lodash';

export function paginate(items,pageNumber,pageSize){
const sindex= (pageNumber-1)* pageSize;
return _(items).slice(sindex).take(pageSize).value();

}

export function getSortIcon(header,colheader,colorder){
if (header=== colheader &&  colorder==='asc') return '<i class="fa fa-sort-asc" aria-hidden="true"></i>'
return "-";

}