//import * as genresAPI from "./fakeGenreService";

const books = [
  {
    _id: "1",
    title: "Book1",
    rating:1,
    img:'B1.jpg',
    type: { _id: "T1", name: "C1" }
   },
  {
    _id: "2",
    title: "Book2",
    rating:1,
    img:'B2.jpg',
    type: { _id: "T1", name: "C1" }
  
  },
  {
    _id: "3",
    title: "Book3",
    rating:2,
    img:'B3.jpg',
    type: { _id: "T1", name: "C1" }
  },
  {
    _id: "4",
    title: "Book4",
    rating:4,
    img:'B4.jpg',
    type: { _id: "T2", name: "C2" }
  
  },
  {
    _id: "5",
    title: "Book5",
    rating:5,
    img:'B5.jpg',
    type: { _id: "T2", name: "C2" }
  },
  {
    _id: "6",
    title: "Book6",
    rating:3,
    img:'B6.jpg',
    type: { _id: "T3", name: "C3" }
  },
  {
    _id: "7",
    title: "Book7",
    rating:3,
    img:'B7.jpg',
    type: { _id: "T4", name: "C4" }
  },
  {
    _id: "8",
    title: "Book8",
    rating:4,
    img:'B8.jpg',
    type: { _id: "T4", name: "C4" }
  },
  {
    _id: "9",
    title: "Book9",
    rating:5,
    img:'B9.jpg',
    type: { _id: "T4", name: "C4" }
  },

  {
    _id: "10",
    rating:3,
    img:'B10.jpg',
    title: "Book10",
    type: { _id: "T5", name: "C5" }
  }
];

export function getBooks() {
  return books;
}

export function getBook(id) {
  return books.find(m => m._id === id);
}
/*
export function saveBook(movie) {
  let movieInDb = books.find(m => m._id === movie._id) || {};
  movieInDb.name = movie.name;
  movieInDb.genre = genresAPI.genres.find(g => g._id === movie.genreId);
  movieInDb.numberInStock = movie.numberInStock;
  movieInDb.dailyRentalRate = movie.dailyRentalRate;

  if (!movieInDb._id) {
    movieInDb._id = Date.now();
    movies.push(movieInDb);
  }

  return movieInDb;
}*/

export function deleteBook(id) {
  let bookInDb = books.find(m => m._id === id);
  books.splice(books.indexOf(bookInDb), 1);
  return bookInDb;
}
