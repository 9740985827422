import React, { Component } from 'react';
import {getBooks} from '../services/tempBookService';
import {getBookType} from '../services/tempTypeService';
import Pagination from './pagination';
import BookType from './bookType';
import { paginate } from './../helper/helpers';
import BooksTable from './booksTable';
import _ from 'lodash'
class Books extends Component {
    state = { 
        books:[],
        maxPageSize:4,
        showbook:[],
        currentPage:1,
        booktypes:[],
        selectedBookType:null,
        colSort:{header:'title',order:'asc'}
    };

    handelViewBook= (book) => {
    this.setState({showbook:[book]});
     console.log(book)
    }

    handelPageClick =(page) =>{
    console.log(page)
    this.setState({currentPage:page})

    };

    
    handelBookTypeSelect =(booktype) =>{
      //  console.log(booktype._id)

        this.setState({selectedBookType:booktype._id,currentPage: 1});
        //this.setState({currentPage:page}) onBookTypeSelect={this.handelBookTypeSelect}
    
        };
    handelSort =(header) =>{
              console.log(header)
              const tempcolsort={... this.state.colSort}
              if(tempcolsort.header===header){
                tempcolsort.order= tempcolsort.order==='asc'?'desc':'asc';
              }
              else{
                tempcolsort.header=header;
                tempcolsort.order='asc';

              }
              this.setState({colSort:tempcolsort});

          
              };


    componentDidMount(){
    this.setState({booktypes:[{_id:"0",name:"ALL Books"},...getBookType()],books:getBooks() });

    }

    
    render( ) { 
        const sbook= this.state.showbook;
        const {books:allbooks,maxPageSize,currentPage,booktypes,selectedBookType,colSort} =this.state;
        console.log("--",selectedBookType)
        const books=  selectedBookType ? allbooks.filter(b => selectedBookType==0 || b.type._id===selectedBookType ):allbooks;
        console.log("books",books);
        const sorted=_.orderBy(books,[colSort.header],[colSort.order])
        const filteredbooks =paginate(sorted,currentPage,maxPageSize);


        return (
            
            <div className="row">
               <div className="col-3">
               <BookType bookTypes={booktypes} onBookTypeSelect={this.handelBookTypeSelect} 
                selectedBookType={selectedBookType} 
               />
               </div>
               <div className="col-9">
              <BooksTable filteredBooks={filteredbooks} 
                  selectedBook={sbook} 
                  onBookView={this.handelViewBook}
                  onSort={this.handelSort} 
                  colSort={colSort}
                  ></BooksTable>
        <Pagination currentPage={this.state.currentPage} booksCount={this.state.books.length} pageSize={this.state.maxPageSize}   onPageClick={this.handelPageClick}></Pagination>
        </div>
        </div>
             );
    }
}
 
export default Books;