import React, { Component } from 'react';
import Input from './input';
class LoginForm extends Component {
    state = { 
      account:{username:"",password:""},
      errors:{}

     }

     validate = () =>{
         const {account}= this.state;
         const errors ={};
         if(account.username.trim()==='') errors.username='Username';
         if(account.password.trim()==='') errors.password='Password';
        return Object.keys(errors).length ===0 ?null :errors;

     }
     validateInput =(ct)=>{
        if(ct.name==='username' && ct.value.trim()==='' ) return 'username';
        else if(ct.name==='password' && ct.value.trim()==='' ) return 'password';
        

     }
     

     handelSubmit = (e) =>{

        e.preventDefault();
        const error=this.validate();
        
        this.setState({errors:error || {}});
        if(error) return;

     }

     handelChange = (e) =>{
    const ct =e.currentTarget;
    const account= {...this.state.account};
    const errors= {...this.state.errros};
    account[ct.name]=ct.value;
    const errorMsg=this.validateInput(ct);
    if (errorMsg) errors[ct.name] =errorMsg;
    else  delete errors[ct.name]; 
    this.setState({account,errors});

     }
    render() { 
        return (
<React.Fragment>
<form onSubmit={this.handelSubmit}>
<Input name="username" value={this.state.account.username} label="username" 
onChange={this.handelChange} type="text" error={this.state.errors.username}></Input>
<Input name="password" value={this.state.account.password} label="password" 
onChange={this.handelChange} type="password" error={this.state.errors.password}></Input>
<button className="btn btn-primary">Login</button>
</form>
</React.Fragment>

        );
    }
}
 
export default LoginForm;