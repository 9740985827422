import React from 'react';
import {Link} from 'react-router-dom'
import Image from './img';
//import { getSortIcon } from './../helper/helpers';



function getSortIcon(header,colheader,colorder){
    
    let rtn="";
    
    if (header=== colheader &&  colorder==='asc') rtn= <i class="fa fa-sort-asc" aria-hidden="true"></i>;
    else if (header=== colheader &&  colorder==='desc') rtn= <i class="fa fa-sort-desc" aria-hidden="true"></i>;
    return rtn;
    
    }

const BooksTable = (props) => {
    const {filteredBooks,selectedBook,onBookView,onSort,colSort} =props;
    
    
    
    return (
        <div>
        {selectedBook.map(book =>
            <table className='table showBook' > 
            <tr  key={book._id}>
                <td><Image src={book.img}/></td>
            <td>
            
            {book.title}<br/>
            </td>
            </tr></table>
            
           )}

        <table className="table">
            <tbody>
            <tr>
                  
                <th onClick={ ()=> onSort('title')}>Title{getSortIcon('title',colSort.header,colSort.order)}</th>
                <th onClick={()=> onSort('type.name')}>Type{getSortIcon('type.name',colSort.header,colSort.order)}</th>
                <th onClick={()=> onSort('rating')}>Rating{getSortIcon('rating',colSort.header,colSort.order)}</th>
                <th >View</th>
            </tr>
           
            {filteredBooks.map(book =><tr className="hover"  key={book._id}>

                <td >
                
                <Link className="nav-link" to={`/books/${book._id}`}>{book.title}</Link>
                </td>
                <td>{book.type.name}</td>
                <td>{book.rating}</td>
                <td onClick={()=>onBookView(book)}>view</td>
                </tr>)}
            </tbody>
            </table>
        </div>

      );
}
 
export default BooksTable;