import React, { Component } from 'react';
import Books from "./components/books";
import BooksForm  from "./components/bookForm";
import LoginForm from './components/loginForm';
import {Route,Redirect,Switch} from "react-router-dom"
import './App.css';
import NotFound from './components/notFound';
import Customer from './components/customers';
import NavBar from './components/navBar';
 


class App extends Component {
  render() {
    return (
      <div className="containier">
      <NavBar></NavBar>
      
      <Switch>
      <Route path="/login" component={LoginForm} ></Route>
      <Route path="/books/:id" component={BooksForm} ></Route>
       <Route path="/books" component={Books} ></Route>
       <Route path="/customers" component={Customer}></Route>
       <Route path="/not-found" component={NotFound}></Route>
       <Redirect from="/" to="/books" exact ></Redirect>
       <Redirect    to="/not-found" ></Redirect>
       </Switch>
     
      
      </div>
    );
  }
}

export default App;
