import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
const Pagination = (props) => {
const {booksCount,pageSize,currentPage,onPageClick}=props;
console.log(props)
const pc = Math.ceil(booksCount/pageSize);
const pages=_.range(1,pc + 1);

    return ( 

<nav aria-label="Page navigation example">
  <ul className="pagination">
   {
    pages.map( page =>
    (<li className={currentPage===page?"page-item active" : "page-item"  }  key={page.toString()}>
    <a key={page} className="page-link" onClick={()=> props.onPageClick(page)} >{page}</a></li>)

   )}
     
    </ul>
</nav>

     );
}

Pagination.propTypes={
    booksCount:PropTypes.number.isRequired,
    pageSize:PropTypes.number.isRequired,
    currentPage:PropTypes.number.isRequired,
    onPageClick:PropTypes.func.isRequired

};
 
export default Pagination;