export const bookTypes = [
  { _id: "T1", name: "C1" },
  { _id: "T2", name: "C2" },
  { _id: "T3", name: "C3" },
  { _id: "T4", name: "C4" },
  { _id: "T5", name: "C5" }

];

export function getBookType() {
  return bookTypes.filter(g => g);
}
